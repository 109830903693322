<template>
    <div>
        <div class="account-block">
            <div class="form-container">
                <div class="form-container-header">
                    <img width="150"
                        src="@/assets/logo.png"
                        alt="">
                    <h1 class="text-dark mb-3"><strong>Welcome to Login Page</strong></h1>
                    <button type="button" class="btn btn-primary" @click="LoginButton">Login</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data(){
            return {
                config:{
                    // tenant_id:'',
                    tenant_id:process.env.VUE_APP_TENANT_ID,  // -- ferring tenant id,
                   //  client_id: '', // local,
                    // client_id: 'b77d9060-48e9-402c-8a78-3a4d6a7cc2d0', // dev,
                    // client_id: 'd1d72c3b-3a0f-4c98-bce5-553beb1b5fab', // qa,
                    client_id: process.env.VUE_APP_CLIENT_ID, // uat,
                     //client_id: '7551ebd8-44a5-4a3d-b677-9180567ae84f', // prod,
                    // client_id: 'd1d72c3b-3a0f-4c98-bce5-553beb1b5fab',
                    redirect_url:  window.location.origin+'/auth/login/webhook',
                    // local
                    // redirect_url: 'https://ferringexhibitfundapp-dev.azurewebsites.net/auth/login/webhook', // dev
                    //redirect_url: 'https://ferringexhibitfundapp-qa.azurewebsites.net/auth/login/webhook', // qa
                   
                     // redirect_url: 'https://ferringexhibitfundapp.azurewebsites.net/auth/login/webhook' // prod
                }
            }
        },
        mounted(){
            this.initialize();
        },
        methods:{
            initialize(){
                let redirect_url = decodeURIComponent(this.$route.query.redirect_url);
                console.log(redirect_url)
                if(redirect_url && redirect_url !== 'undefined'){
                    localStorage.setItem('redirect_url',redirect_url);
                }
            },
            generateRandomString(length) {
                var result           = '';
                var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                var charactersLength = characters.length;
                for ( var i = 0; i < length; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            },

            generateStateCode(){
                let code = this.generateRandomString(10);
                localStorage.setItem('state_code',code);
                return code;
            },  
            LoginButton(){
                let url = `https://login.microsoftonline.com/${this.config.tenant_id}/oauth2/v2.0/authorize?client_id=${this.config.client_id}&response_type=code&redirect_uri=${this.config.redirect_url}&response_mode=query&scope=openid offline_access&state=${this.generateStateCode()}`
                window.location.href= url;
            }
        }
    }
</script>
<style lang="css" scoped>
.account-block {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 30rem;
  width: 100%;
  padding: 1rem;
}

/* line 15, src/sass/pages/account.scss */
.account-block .btn-primary {
  background: #2CA3DC !important;
  border-color: #2CA3DC !important;
}

/* line 18, src/sass/pages/account.scss */
.account-block .btn-primary:hover {
  background: #58b6e3 !important;
}

</style>