<template>
    <div class="home">
      <BlockUI :message="msg" v-if="loading">
          <img :src="url" alt="Loading" width="60">
      </BlockUI>
      <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body" >
        <tr>
          <td>&nbsp;</td>
          <td class="container">
            <div class="content">
              <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                <tbody>
                  <tr>
                    <td>
                      <a href="https://www.ferring.com/" target="_blank">
                        <img style="border: 0; display: block; margin: auto; width: 100px;" width="100" src="https://master-3.corporate.ferring.tech/wp-content/uploads/sites/16/2021/01/Ferring-logo.png" alt="Ferring Logo">
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- START CENTERED WHITE CONTAINER -->
              <form action="">
                <table role="presentation" class="main">
                  <tr>
                    <td class="wrapper">
                      <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                        <tr>
                          <td>
                            <div class="table-preview">
                              <table class="table-group" role="presentation" border="0" cellpadding="0" cellspacing="0">
                                <tbody>
                                  <tr>
                                    <td>Requested by: </td>
                                    <td class="last-child">{{ program_details.CreatedByName }}</td>
                                  </tr>
                                  <tr>
                                    <td>Territory #:</td>
                                    <td class="last-child">{{ program_details.Territory_Number }}</td>
                                  </tr>
                                  <tr>
                                    <td>Business Unit:</td>
                                    <td class="last-child">{{ program_details.BusinessUnitName }}</td>
                                  </tr>
                                  <tr>
                                    <td>District:</td>
                                    <td class="last-child">{{ program_details.DistrictName }}</td>
                                  </tr>
                                  <tr>
                                    <td>Brand Name:</td>
                                    <td class="last-child">{{ program_details.BrandName }}</td>
                                  </tr>
                                  <tr>
                                    <td>Date of Exhibit:</td>
                                    <td class="last-child">{{ program_details.Date_Of_Program }}</td>
                                  </tr>
                                  <tr>
                                    <td>Requesting Organization:</td>
                                    <td class="last-child">{{ program_details.Requesting_Organization }}</td>
                                  </tr>
                                  <tr>
                                    <td>Title of Exhibit:</td>
                                    <td class="last-child">{{ program_details.Title_Of_Program }}</td>
                                  </tr>
                                  <tr>
                                    <td>Exhibit Location:</td>
                                    <td class="last-child">{{ program_details.Program_Location }}</td>
                                  </tr>
                                
                                  <tr>
                                    <td>Speaker Center Name (As appears on the w-9):</td>
                                    <td class="last-child">{{ program_details.Speaker_Center_Name }}</td>
                                  </tr>
                                  <tr>
                                    <td>Speaker Center Address (As appears on the w-9):</td>
                                    <td class="last-child">{{ program_details.Speaker_Center_Address }}</td>
                                  </tr>
                                  <tr>
                                    <td>Address to send check: </td>
                                    <td class="last-child">{{ program_details.Address_to_send_Check }}</td>
                                  </tr>
                                  <tr>
                                    <td>Dollar Amount requested:</td>
                                    <td class="last-child">$ {{ program_details.Dollar_amt }}</td>
                                  </tr>
                                  <tr>
                                    <td>Type of Cost:</td>
                                    <td class="last-child">{{ program_details.Type_Of_Cost }}</td>
                                  </tr>
                                  <tr>
                                    <td>Is Credit Card payment accepted? :</td>
                                    <td class="last-child">{{ program_details.Is_CreditCard_Payment }}</td>
                                  </tr>
                                  <tr>
                                    <td>W-9 attached :</td>
                                    <td class="last-child">{{ program_details.Is_W9_Attached }}</td>
                                  </tr>
                                
                                  <tr class="last-child">
                                    <td>Attachments :</td>
                                    <td class="last-child">
                                
                                      <div v-for="(a,i) in program_details.Attachments" :key="i">
  
                                        <a :href="a.url"  target="__blank" download>{{ a.originalname  }}</a>
                                      </div>
                                    </td>
                                  </tr>
                                  
                                  <tr v-if="isReject">
                                    <td >
                                      Please provide the reason for cancellation  <span style="color:red">*</span>
                                    </td>
                                    <td >
                                      <textarea name="" id="" cols="20" rows="10" v-model="comment"></textarea>
                                    </td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          
  
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
  
                <!-- END MAIN CONTENT AREA -->
                </table>
              </form>
              <!-- END CENTERED WHITE CONTAINER -->
  
              <!-- START FOOTER -->
              <div class="footer">
                <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                  <tr>
                    <td class="content-block powered-by">
                      Powered by <a href="https://www.p360.com/" target="_blank">P360</a>
                    </td>
                  </tr>
                </table>
              </div>
              <!-- END FOOTER -->
  
            </div>
          </td>
          <td>&nbsp;</td>
        </tr>
      </table>
    </div>
  </template>
  <script>
  // @ is an alias to /src
  
  import loadingImage from '@/assets/load.gif'
  import axios from 'axios';
  export default {
    name: 'EmailResponseView',
    components: {
       showAccept: false,
       showReject: false
    },
    data(){ 
        return {
            isReject : false,
            msg: 'Loading ... ',
            url : loadingImage ,
            loading: true,
            showAccept: false,
            showReject:false,
            program_details:'',
            comment:''
        }
    },
    mounted(){
        this.getProgramDetails();
    },
    methods:{
      async getProgramDetails(){
          let program_id= this.$route.query.program_id;
          
          axios.get('/exhibit-fund/'+program_id+'/details')
            .then(resp => {
              console.log("Resp",resp);
              if(resp.data.data){
              this.program_details = resp.data.data;
              this.program_details.Attachments = JSON.parse(resp.data.data.Attachments);
              this.loading = false;
              }else {
                this.loading = false;
                  this.$fire({
                    title: "This Exhibit Fund Has been deleted!",
                    type: "info",
                  }).then(r => {
                    this.$router.push('/');
                  });
              }
            })
      },
      
    }
  }
  </script>
  <style lang="css" scoped>
    
        /* -------------------------------------
            GLOBAL RESETS
        ------------------------------------- */
        
        /*All the styling goes here*/
        
        *{
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }
  
        img {
          border: none;
          -ms-interpolation-mode: bicubic;
          max-width: 100%; 
        }
  
        body {
          background-color: #f6f6f6;
          font-family: sans-serif;
          -webkit-font-smoothing: antialiased;
          font-size: 14px;
          line-height: 1.4;
          margin: 0;
          padding: 0;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%; 
        }
  
        table {
          border-collapse: separate;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          width: 100%; }
          table td {
            font-family: sans-serif;
            font-size: 14px;
            vertical-align: top; 
        }
  
        /* -------------------------------------
            BODY & CONTAINER
        ------------------------------------- */
  
        .body {
          background-color: #f6f6f6;
          width: 100%; 
        }
  
        /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
        .container {
          display: block;
          margin: 0 auto !important;
          /* makes it centered */
          max-width: 580px;
          padding: 10px;
          width: 580px; 
        }
  
        /* This should also be a block element, so that it will fill 100% of the .container */
        .content {
          box-sizing: border-box;
          display: block;
          margin: 0 auto;
          max-width: 580px;
          padding: 10px; 
        }
  
        /* -------------------------------------
            HEADER, FOOTER, MAIN
        ------------------------------------- */
        .main {
          background: #ffffff;
          border-radius: 3px;
          width: 100%; 
        }
  
        .wrapper {
          box-sizing: border-box;
          padding: 20px; 
        }
  
        .content-block {
          padding-bottom: 10px;
          padding-top: 10px;
        }
  
        .footer {
          clear: both;
          margin-top: 10px;
          text-align: center;
          width: 100%; 
        }
          .footer td,
          .footer p,
          .footer span,
          .footer a {
            color: #999999;
            font-size: 12px;
            text-align: center; 
        }
  
        /* -------------------------------------
            TYPOGRAPHY
        ------------------------------------- */
        h1,
        h2,
        h3,
        h4 {
          color: #000000;
          font-family: sans-serif;
          font-weight: 400;
          line-height: 1.4;
          margin: 0;
          margin-bottom: 15px; 
        }
  
        h1 {
          font-size: 35px;
          font-weight: 300;
          text-align: center;
          text-transform: capitalize; 
        }
  
        p,
        ul,
        ol {
          font-family: sans-serif;
          font-size: 14px;
          font-weight: normal;
          margin: 0;
          margin-bottom: 15px; 
        }
          p li,
          ul li,
          ol li {
            list-style-position: inside;
            margin-left: 5px; 
        }
  
        a {
          color: #0088ce;
          text-decoration: underline; 
        }
  
        /* -------------------------------------
            BUTTONS
        ------------------------------------- */
        .btn {
          box-sizing: border-box;
          width: 100%;
        }
        /* .btn > tbody > tr > td {
          padding-bottom: 15px;
        } */
        .btn table {
          width: 100%; 
        }
        .btn table td,
        .btn a{
          border-radius: 5px 5px 0 0;
          background-color: #ffffff;
          transition: 0.25s background-color ease-in-out, 0.25s border-color ease-in-out;
        }
        .btn table td {
          text-align: center;
        }
        .btn a {
          border: solid 1px #0088ce;
          box-sizing: border-box;
          color: #0088ce;
          cursor: pointer;
          display: block;
          font-size: 14px;
          font-weight: bold;
          margin: 0;
          padding: 12px 25px;
          text-decoration: none;
          text-transform: capitalize; 
        }
  
        .btn-border{
          height: 5px;
          width: 100%;
          border-radius: 0 0 5px 5px;
        }
  
        .btn-success table td,
        .btn-success a {
          background-color: #5fcf80; 
        }
  
        .btn-success a {
          border-color: #5fcf80;
          color: #ffffff; 
        }
        .btn-success-border{
          background-color: #3ac162;
        }
  
        .btn-danger table td,
        .btn-danger a {
          background-color: #ed5a5a; 
        }
  
        .btn-danger a {
          border-color: #ed5a5a;
          color: #ffffff; 
        }
        .btn-danger-border{
          background-color: #ea4343;
        }
  
        /* -------------------------------------
            OTHER STYLES THAT MIGHT BE USEFUL
        ------------------------------------- */
        .last {
          margin-bottom: 0; 
        }
  
        .first {
          margin-top: 0; 
        }
  
        .align-center {
          text-align: center; 
        }
  
        .align-right {
          text-align: right; 
        }
  
        .align-left {
          text-align: left; 
        }
  
        .clear {
          clear: both; 
        }
  
        .mt0 {
          margin-top: 0; 
        }
  
        .mb0 {
          margin-bottom: 0; 
        }
  
        /* Custom */
        .form-group{
          margin-bottom: 20px;
        }
        .form-control{
          font-family: inherit;
          padding: 10px;
          width: 100%;
          display: block;
          border-radius: 5px;
          border: 1px solid #d0d0d0;
        }
  
        .pb-2{
          padding-bottom: 20px;
        }
        .table-sheet tbody tr td{
          border: 1px solid #d0d0d0;
          border-right: 0;
          border-bottom: 0;
          padding: 10px;
          font-weight: 600;
        }
        .table-sheet,
        .table-group{
          margin-bottom: 20px;
        }
        .table-sheet-col-first{
          color: #4a4a4a;
        }
        .table-sheet-col-last{
          color: #0088ce;
          border-right: 1px solid #d0d0d0 !important;
        }
        .table-sheet-last-row td{
          border-bottom: 1px solid #d0d0d0 !important;
        }
        .table-group{
          /* border: 1px solid #d0d0d0; */
          /* padding: 10px; */
        }
        .table-group tbody tr td{
          padding: 10px;
          border-bottom: 1px solid #d0d0d0;
        }
        .table-group tbody tr.last-child td{
          border-bottom: 0;
        }
        .table-group tbody tr td.last-child{
          font-weight: 600;
        }
        .table-preview{
          background-color: #eee;
          padding: 20px;
          margin-bottom: 20px;
        }
        .table-preview *{
          font-size: 12px !important;
        }
        .table-preview .table-group{
          margin-bottom: 0;
        }
        .table-preview .table-group tbody tr td{
          padding: 8px 10px;
        }
        /* Custom */
  
        .preheader {
          color: transparent;
          display: none;
          height: 0;
          max-height: 0;
          max-width: 0;
          opacity: 0;
          overflow: hidden;
          mso-hide: all;
          visibility: hidden;
          width: 0; 
        }
  
        .powered-by a {
          text-decoration: none; 
        }
  
        hr {
          border: 0;
          border-bottom: 1px solid #f6f6f6;
          margin: 20px 0; 
        }
  
        /* -------------------------------------
            RESPONSIVE AND MOBILE FRIENDLY STYLES
        ------------------------------------- */
        @media only screen and (max-width: 620px) {
          table.body h1 {
            font-size: 28px !important;
            margin-bottom: 10px !important; 
          }
          table.body p,
          table.body ul,
          table.body ol,
          table.body td,
          table.body span,
          table.body a {
            font-size: 16px !important; 
          }
          table.body .wrapper,
          table.body .article {
            padding: 10px !important; 
          }
          table.body .content {
            padding: 0 !important; 
          }
          table.body .container {
            padding: 0 !important;
            width: 100% !important; 
          }
          table.body .main {
            border-left-width: 0 !important;
            border-radius: 0 !important;
            border-right-width: 0 !important; 
          }
          table.body .btn table {
            width: 100% !important; 
          }
          table.body .btn a {
            width: 100% !important; 
          }
          table.body .img-responsive {
            height: auto !important;
            max-width: 100% !important;
            width: auto !important; 
          }
        }
  
        /* -------------------------------------
            PRESERVE THESE STYLES IN THE HEAD
        ------------------------------------- */
        @media all {
          .ExternalClass {
            width: 100%; 
          }
          .ExternalClass,
          .ExternalClass p,
          .ExternalClass span,
          .ExternalClass font,
          .ExternalClass td,
          .ExternalClass div {
            line-height: 100%; 
          }
          .apple-link a {
            color: inherit !important;
            font-family: inherit !important;
            font-size: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
            text-decoration: none !important; 
          }
          #MessageViewBody a {
            color: inherit;
            text-decoration: none;
            font-size: inherit;
            font-family: inherit;
            font-weight: inherit;
            line-height: inherit;
          }
          .btn-success table td:hover,
          .btn-success a:hover {
            background-color: #3ac162 !important; 
          }
          .btn-success a:hover {
            border-color: #3ac162 !important; 
          } 
          .btn-danger table td:hover,
          .btn-danger a:hover {
            background-color: #ea4343 !important; 
          }
          .btn-danger a:hover {
            border-color: #ea4343 !important; 
          } 
        }
  </style>
  